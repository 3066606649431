import axios from "axios";
import ApiService from "@clients/src/services/api.service";

export const wiki = {
  state: () => ({
    contactDetails: [],
    classType: null,
    classTypes: [],
    inquiryProducts: [],
    policyClass: [],
    policyClasses: [],
    classesAndProducts: [],
    currencyCodes: [],
    currencies: [],
    selectedCurrencyCode: "USD",
    requestedCurrencyCode: "USD",
    masterCurrencyCode: "USD",
    selectedCurrencyRate: 1.0,
    currencyCodeFilter: "",
    loadingWikiData: false,
    countriesWithOffices: [],
  }),
  mutations: {
    changeClassOfBusiness(state, payload) {
      state.classType = payload.classType;
    },
    changePolicyCLass(state, payload) {
      state.policyClass = payload.policyClass;
    },
    setClassTypes(state, { classTypes }) {
      state.classTypes = classTypes;
    },
    setPolicyClasses(state, { policyClasses }) {
      state.policyClasses = Array.isArray(policyClasses) ? policyClasses : (policyClasses ? [policyClasses] : []);
    },
    setInquiryProducts(state, { inquiryProducts }) {
      state.inquiryProducts = Array.isArray(inquiryProducts) ? inquiryProducts : [inquiryProducts];
    },
    resetInquiryProducts(state) {
      state.inquiryProducts = [];
    },
    setMasterCurrency(state, payload) {
      state.masterCurrencyCode = payload.masterCurrency;
    },
    loadWikiStateFromStorage(state, payload) {
      state.contactDetails = payload.contactDetails;
      state.classType = payload.classType;
      state.policyClasses = Array.isArray(payload.policyClass) ? payload.policyClass : [payload.policyClass];
      state.selectedCurrencyCode = payload.selectedCurrencyCode
        ? payload.selectedCurrencyCode
        : "USD";
      state.requestedCurrencyCode = payload.selectedCurrencyCode
          ? payload.selectedCurrencyCode
          : "USD";
    },
    setCurrencyCodes(state, payload) {
      state.currencyCodes = payload.currencyCodes;
    },
    setCurrencies(state, { currencies }) {
      state.currencies = currencies;
    },
    setClassesAndProducts(state, {classesAndProducts}) {
      state.classesAndProducts = classesAndProducts;
    },
    setCountriesAndOffices(state, {countriesWithOffices}) {
      state.countriesWithOffices = countriesWithOffices;
    },
    setCurrencyCode(state, payload) {
      state.selectedCurrencyCode = payload.code;
      const currency = state.currencies.find(currency => currency.code === state.selectedCurrencyCode);
      if (currency !== undefined) {
        state.selectedCurrencyRate = currency.rate;
      }
      state.policyLimit = null;
    },
    setCurrencyCodeFilter(state, payload) {
      if (
        state.currencyCodes.filter(code =>
          code.startsWith(payload.filter.toUpperCase())
        ).length
      ) {
        state.currencyCodeFilter = payload.filter;
      }
    },
    setCurrencyData(state, payload) {
      state.selectedCurrencyCode = payload.currencyCode
        ? payload.currencyCode
        : state.selectedCurrencyCode;

      state.requestedCurrencyCode = state.selectedCurrencyCode;
    },
    setLoadingWikiData(state, { loading }) {
      state.loadingWikiData = loading;
    },
    clearClassTypes(state) {
      state.classTypes = [];
      state.classType = null;
      state.policyClass = [];
    }
  },
  actions: {
    async getWikiCapabilitiesData({ state, commit, rootGetters }) {
      commit("setLoadingWikiData", { loading: true });
      try {
        let { data } = await axios.post(
          process.env.VUE_APP_API_ENDPOINT + "/wiki/countryData",
          {
            countries: rootGetters.getSelectedCountries.map(
              country => country.country_id
            ),
            policyLimit: rootGetters.getPolicyLimit,
            classType: state.classType.policy_class_type_caption,
            policyClass: state.policyClass[0].policy_class_caption, // Only send the first product
            currencyCode: state.selectedCurrencyCode,
            // Use the latest rate for now
            // dateOfRate: rootGetters.dateOfCurrencyRate
          },
          {
            withCredentials: true
          }
        );
        commit("setLoadingWikiData", { loading: false });
        return data;
      } catch (err) {
        commit("setLoadingWikiData", { loading: false });
        console.log("AXIOS country wiki data error");
        console.error(err);
      }
    },
    async generateWikiCapabilitiesData({ dispatch, commit, rootGetters }) {
      commit("setLocalPolicies", { localPolicies: [] });
      let capabilitiesData = [];
      const wikiData = await dispatch("getWikiCapabilitiesData");

      const pickRandom = function(things) {
        return things[Math.floor(Math.random() * things.length)];
      };

      commit("setCurrencyData", wikiData.currencyData);
      commit("setPolicyCurrencyData", wikiData.currencyData);

      const countryData = wikiData.countryData;

      capabilitiesData = rootGetters.getSelectedCountries
        .filter(country => {
          const dbData = countryData.find(
            d => d.options.country.country_id == country.country_id
          );
          if (!dbData) {
            console.log(
              "couldn't find db data for the country with ID: " +
                country.country_id
            );
            return false;
          }
          return true;
        })
        .map(country => {
          const dbData = countryData.find(
            d => d.options.country.country_id == country.country_id
          );

          const check = {
            options: dbData.options,
            overallLimit: "",
            overallDeductible: "",
            turnover: 0,
            employees: 0,
            covers: {},
            showExtra: false,
            networkPartnerLegalName: "",
            affiliation: "Affiliated",
          };

          for (let i = 1; i <= 8; i++) {
            // FIXME: Again, need to use real data here
            const allowInclude = pickRandom([
              true,
              true,
              true,
              true,
              true,
              true,
              true,
              false
            ]);
            check.covers[i] = {
              id: i,
              included: 1,
              occurrenceLimit: 1234567,
              aggregateLimit: 7654321,
              maxDeductible: 321,
              minDeductible: 123,
              percentageDeductible: 3,
              allowInclude,
              allowExclude: !allowInclude
            };
          }

          let id = 1;
          for (let item of Object.keys(check.options)) {
            if (
              !Object.prototype.hasOwnProperty.call(check.options[item], "id")
            ) {
              check.options[item].id =
                check.options.country.id + "capability" + id;
              id++;
              check.options[item].userValue = null;
            }
          }

          return check;
        });
      capabilitiesData.forEach(d => {
        if (d === undefined) {
          console.log("found undefined");
        } else {
          d.selectedWikiItem = null;
        }
      });
      dispatch("createLocalPoliciesFromWikiData", {
        wikiData: capabilitiesData
      });
    },
    async loadClassTypes({ commit, dispatch }) {
       try {
        const classTypesResponse = await axios.get(
            process.env.VUE_APP_API_ENDPOINT +
            "/wiki/classTypes",
            {
              withCredentials: true
            }
        );
        if (classTypesResponse.status === 200) {
          const classTypes = classTypesResponse.data;
          commit("setClassTypes", { classTypes });
        } else {
          console.error(classTypesResponse);
        }
      } catch (err) {
        console.log("AXIOS class type data error");
        console.error(err);
      }
     
    },
    async changeClassOfBusiness({ commit, dispatch, getters }, payload) {
      commit("changeClassOfBusiness", payload);
      commit("setPolicyClasses", {policyClasses: []});
      commit("changePolicyCLass", {policyClass: null});
      if (getters.classType == null) {
        return;
      }
      // Load the policy classes for this class type
      try {
        const policyClassesResponse = await axios.get(
            process.env.VUE_APP_API_ENDPOINT +
            "/wiki/policyClasses/" +
            getters.classType.policy_class_type_caption,
            {
              withCredentials: true
            }
        );
        if (policyClassesResponse.status === 200) {
          commit("setPolicyClasses", { policyClasses: policyClassesResponse.data });
          if (policyClassesResponse.data.length > 0) {
            dispatch("changePolicyClass", { policyClass: [] });
          }
        } else {
          console.error(policyClassesResponse);
          console.error("There was an error with the above request");
        }
      } catch (err) {
        console.log("AXIOS policy classes data error");
        console.error(err);
      }
    },
    changePolicyClass({ commit, state }, {policyClass}) {
      if (policyClass === null) {
        commit("changePolicyCLass", {policyClass});
        return;
      }

      const bPolicyClassAvailable = policyClass.some(
          newPolicyClass => state.policyClasses.some(
              existingPolicyClass => existingPolicyClass.policy_class_caption === newPolicyClass.policy_class_caption
          )
      );

      if (bPolicyClassAvailable) {
        commit("changePolicyCLass", {policyClass});
      }
    },
    setCurrencyCode({commit, dispatch}, { code }) {
      commit('setCurrencyCode', { code });
      // Reset the policy limit on currency change
      dispatch('changePolicyLimit', {policyLimit: null});
    },
    // @deprecated Need to remove this in the future
    async loadCurrencyCodes({ state, commit }) {
      if (state.currencyCodes.length === 0) {
        try {
          let { data } = await axios.get(
            process.env.VUE_APP_API_ENDPOINT + "/wiki/currencyCodes",
            {
              withCredentials: true
            }
          );
          commit("setCurrencyCodes", { currencyCodes: data });
        } catch (err) {
          console.log("Error Loading Currency");
          console.error(err);
        }
      }
    },
    async loadCurrencies({ commit }) {
      try {
        let { data } = await axios.get(
            process.env.VUE_APP_API_ENDPOINT + "/wiki/currencies",
            {
              withCredentials: true
            }
        );
        commit("setCurrencies", { currencies: data });
        // TODO: check if this is needed
        //  1. Could be the case where the currency selected is no longer available, but that's unlikely
        // dispatch("setCurrencyCode", { code: 'USD' })
      } catch (err) {
        console.log("AXIOS wiki currency code error:");
        console.error(err);
      }
    },
    async loadClassesAndProducts({ commit }) {
      try {
        let { data } = await ApiService.get(
            process.env.VUE_APP_API_ENDPOINT + "/wiki/getClassesAndProducts",
            {
              withCredentials: true
            }
        );
        commit("setClassesAndProducts", { classesAndProducts: data.classesAndProducts });
      } catch (err) {
        console.log("Could not retrieve Classes and Products:");
        console.error(err);
      }
    },
    async loadCountriesWithOffices({commit}) {
      try {
        let { data } = await ApiService.get(
            process.env.VUE_APP_API_ENDPOINT + "/company/list",
            {
              withCredentials: true
            }
        );
        commit("setCountriesAndOffices", { countriesWithOffices: data.companies });
      } catch (err) {
        console.log("Could not retrieve country list");
        console.error(err);
      }
    }
  },
  getters: {
    // TODO:: Clean this list. A lot of the names are too ambiguous and it's not clear what they are for.
    // For example, can you tell at a first glance what is the difference between policyClass, policyClasses and classesAndProducts OR selectedCurrencyCode, requestedCurrencyCode?
    // All of these will need to be renamed to something more sensible so it's easy to figure out what they are for.

    wikiStateForStorage: state => {
      return {
        contactDetails: state.contactDetails,
        classType: state.classType,
        policyClass: state.policyClass,
        selectedCurrencyCode: state.selectedCurrencyCode,
        requestedCurrencyCode: state.requestedCurrencyCode
      };
    },
    countriesWithOffices: state => state.countriesWithOffices,
    classTypes: state => state.classTypes,
    classType: state => state.classType,
    policyClass: state => state.policyClass,
    policyClasses: state => state.policyClasses,
    inquiryProducts: state => state.inquiryProducts,
    currencyCodes: state => state.currencies.filter(currency => currency.code.startsWith(state.currencyCodeFilter.toUpperCase())).map(currency => currency.code),
    currenciesFiltered: state => state.currencies.filter(currency => currency.code.startsWith(state.currencyCodeFilter.toUpperCase())),
    classesAndProducts: state => state.classesAndProducts,
    selectedCurrencyCode: state => state.selectedCurrencyCode,
    requestedCurrencyCode: state => state.requestedCurrencyCode,
    currencyCodeFilter: state => state.currencyCodeFilter,
    masterCurrencyCode: state => state.masterCurrencyCode,
    shouldShowPricingMatrixElements: state => state.classType.policy_class_type_caption === "Financial Lines",
    isLoadingWikiData: state => state.loadingWikiData,
    selectedCurrencyRate: state => {
      return state.selectedCurrencyRate;
    },
    upcomingRenewals: state => {
      return {
        classType: state.classType,
      };
    }
  }
};
