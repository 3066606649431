import ApiService from "@/services/api.service";
import {
  SET_LOADING_LOCAL_POLICIES,
  CLEAR_LOCAL_POLICIES,
  SET_LOADING_PROGRAMMES,
  SET_PROGRAMME,
  CLEAR_PROGRAMME,
  SET_PROGRAMMES,
  CLEAR_PROGRAMMES,
  ADD_CLAIM,
  ADD_LOCAL_POLICY,
  UPDATE_DOCUMENT_LABELS,
  SET_BIND_REQUEST,
  CLEAR_INQUIRIES
} from "@underwriters/src/store/mutation-types";
import UnderwritingService from "@clients/src/services/underwriting.service";
import moment from "moment";
import ClaimService from "@clients/src/services/claim.service";
import { ADD_COVERAGE_DOCUMENT } from "@clients/src/store/mutation-types";
import Vue from "vue";
import axios from "axios";

export const underwriting = {
  state: () => ({
    inquiries: [],
    loadingInquiries: false,
    inquiryProgressDocumentTypes: [],
    programme: null,
    programmes: [],
    localPolicies: [],
    claims: [],
    coverageDocuments: [],
    handoverNotes: ''
  }),
  mutations: {
    setInquiryProgressDocumentTypes(state, payload) {
      state.inquiryProgressDocumentTypes = payload;
    },
    setLoadingInquiries(state, { loading }) {
      state.loadingInquiries = loading;
    },
    setInquiries(state, payload) {
      state.inquiries = payload.programmes;
    },
    [CLEAR_INQUIRIES](state) {
      state.inquiries = [];
    },
    setHandoverNotes(state, payload) {
      state.handoverNotes = payload.handoverNotes;
    },
    [SET_PROGRAMMES](state, payload) {
      state.programmes = payload.programmes;
    },
    [SET_PROGRAMME](state, payload) {
      state.programme = payload.programme;
    },
    [ADD_LOCAL_POLICY](state, { localPolicy }) {
      state.localPolicies.push(localPolicy);
    },
    [CLEAR_PROGRAMMES](state) {
      state.programmes = [];
      state.localPolicies = [];
      state.coverageDocuments = [];
      state.claims = [];
      state.handoverNotes = '';
    },
    [CLEAR_PROGRAMME](state, { programmeId }) {
      state.programme = null;

      const removingLocalPolicies = state.localPolicies
        .filter(localPolicy => localPolicy.master_policy_id == programmeId)
        .map(localPolicy => localPolicy.local_policy_id);
      state.coverageDocuments = state.coverageDocuments.filter(
        coverageDocument =>
          removingLocalPolicies.indexOf(coverageDocument.local_policy_id) === -1
      );
      state.claims = state.claims.filter(
        claim => removingLocalPolicies.indexOf(claim.local_policy_id) === -1
      );
    },
    [CLEAR_LOCAL_POLICIES](state, { master_policy_id }) {
      if (master_policy_id === undefined) {
        state.localPolicies = [];
      } else {
        state.localPolicies = state.localPolicies.filter(
          lpol => lpol.master_policy_id != master_policy_id
        );
      }
    },
    [SET_LOADING_PROGRAMMES](state, { loading }) {
      state.loadingProgrammes = loading;
    },
    [SET_LOADING_LOCAL_POLICIES](state, { loading }) {
      state.loadingLocalPolicies = loading;
    },
    [ADD_CLAIM](state, { claim }) {
      state.claims = state.claims.filter(currentClaim => currentClaim.claim_id!==claim.claim_id);
      state.claims.push(claim);
    },
    [ADD_COVERAGE_DOCUMENT](state, { document }) {
      state.coverageDocuments = state.coverageDocuments.filter(currentDocument => currentDocument.coverage_document_header_id!==document.coverage_document_header_id);
      state.coverageDocuments.push(document);
    },
    [UPDATE_DOCUMENT_LABELS](state, { documents, label }) {
      state.inquiries.forEach(inquiry => {
        inquiry.documents.forEach(documentGroup => {
          documentGroup.forEach(document => {
            const documentIds = documents.map(document => document.document.document_id);

            if (documentIds.includes(document.document_id)) {
              document.document_label = label;
            }
          });
        });
      });
    },
    [SET_BIND_REQUEST](state, { inquiryId, bindRequest }) {
      state.inquiries.forEach(inquiry => {
        if (inquiry.id === inquiryId && bindRequest) {
          Vue.set(inquiry.mpol, 'activeBindRequests', [bindRequest]);
        }
      });
    },
  },
  actions: {
    async loadInquiryProgressDocumentTypes({ commit }) {
      try {
        let { data } = await ApiService.get(
          "progress/inquiryProgressDocumentTypes"
        );

        commit("setInquiryProgressDocumentTypes", data);
      } catch (err) {
        console.error("occurred while fetching document types:", err);
        return false;
      }
    },
    async loadInquiries({ commit }) {
      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/inquiries";

      commit("setLoadingInquiries", { loading: true });
      try {
        let { data } = await ApiService.get(url);

        let programmes = data.programmes;

        commit({ type: "setInquiries", programmes });
      } catch (err) {
        console.error(err);
      }

      commit("setLoadingInquiries", { loading: false });
    },
    async setInquiries({ commit }, inquiries) {
      commit('setInquiries', inquiries);
    },
    async getProgrammes({ commit, rootGetters }) {
      commit(CLEAR_PROGRAMMES);
      commit(SET_LOADING_PROGRAMMES, { loading: true });
      const { programmes } = await UnderwritingService.getProgrammes(
        rootGetters.selectedClientId
      );
      if (!programmes) {
        // There was an error while getting programmes from the API
        console.log("Didn't receive any programmes...");
        return;
      }
      const statuses = [
        ["On Track", "info"],
        ["Requires Attention", "warning"],
        ["Active", "success"],
        ["Expired", "secondary"]
      ];
      // TODO: Refactor this...
      const classes = [
        ["Property", "building"],
        ["Marine", "water"],
        ["Financial Lines", "dollar-sign"],
        ["Liability", "users"],
        ["Engineering", "cogs"]
      ];
      for (let programme of programmes) {
        const expiry = moment(String(programme["expiry"]));
        if (expiry.diff(moment()) < 0) {
          programme["status"] = statuses[3][0];
          programme["statusType"] = statuses[3][1];
        } else {
          programme["status"] = statuses[1][0];
          programme["statusType"] = statuses[1][1];
        }
        // programme['status'] = status[0];
        // programme['statusType'] = status[1];
        programme["highlighted"] = false;
        const c = classes.find(cl => cl[0] == programme.class);
        programme["icon"] = "users";
        if (c) {
          programme["icon"] = c[1];
        }
        // for (let _ = 0; _ < programme.lpolCount; _++) {
        //     dispatch('createLocalPolicy', {mPolId: programme.id, lPolId: (programme.id * 100) + _});
        // }
      }
      programmes.sort((a, b) => (a.name < b.name ? -1 : 1));
      commit(SET_PROGRAMMES, { programmes });
      commit(SET_LOADING_PROGRAMMES, { loading: false });
    },
    async getProgramme({ commit, rootGetters }, { programmeId }) {
      commit(SET_LOADING_PROGRAMMES, { loading: true });
      commit(CLEAR_LOCAL_POLICIES, { master_policy_id: programmeId });
      commit(CLEAR_PROGRAMME, { programmeId });
      const { success, programme } = await UnderwritingService.getProgramme(
        rootGetters.selectedClientId,
        programmeId
      );
      if (!success || typeof programme === "undefined") {
        commit(SET_LOADING_PROGRAMMES, { loading: false });
        return false;
      }
      commit(SET_PROGRAMME, { programme });
      commit(SET_LOADING_PROGRAMMES, { loading: false });
      return true;
    },
    async getLocalPolicies({ rootGetters, commit }, { master_policy_id }) {
      // 2021-09-14 - Removed code to skip data refresh if already available in store

      commit(SET_LOADING_LOCAL_POLICIES, { loading: true });
      commit(CLEAR_LOCAL_POLICIES, { master_policy_id });
      const {
        localPolicies
      } = await UnderwritingService.getLocalPoliciesForProgramme(
        master_policy_id,
        rootGetters.selectedClientId
      );
      if (!localPolicies || localPolicies.length === 0) {
        console.log(
          "No local policies found for master_policy_id: " + master_policy_id
        );
        commit(SET_LOADING_LOCAL_POLICIES, { loading: false });
        return false;
      }

      for (let localPolicy of localPolicies) {
        commit(ADD_LOCAL_POLICY, { localPolicy });
      }
      commit(SET_LOADING_LOCAL_POLICIES, { loading: false });
      return true;
    },
    async loadClaimsForLocalPolicy({ rootGetters, commit }, { local_policy_id }) {
      // 2021-09-14 - Removed code to skip data refresh if already available in store

      const { claims } = await ClaimService.getClaimsForLocalPolicy(rootGetters.selectedClientId, local_policy_id);
      if (claims.length === 0) {
        return;
      }
      for (const claim of claims) {
        commit(ADD_CLAIM, { claim });
      }
    },
    async loadCoverageDocument({rootGetters, commit}, { programmeId, localPolicyId }) {
      // 2021-09-14 - Removed code to skip data refresh if already available in store

      const { document } = await UnderwritingService.getCoverageDocument(rootGetters.selectedClientId, programmeId, localPolicyId);
      if (!document) {
        console.error("No Coverage Document found for MPol ID", programmeId, "and LPol ID", localPolicyId);
        return false;
      }
      commit(ADD_COVERAGE_DOCUMENT, { document });
      return true;
    },
    programmeExists({ state }, { programmeId }) {
      const prog = state.programmes.find(prog => prog.id==programmeId);
      return (typeof prog !== 'undefined');
    },
    updateDocumentLabels({ commit }, { documents, label }) {
      commit(UPDATE_DOCUMENT_LABELS, { documents, label });
    },
    setBindRequest({ commit }, { inquiryId, bindRequest }) {
      commit(SET_BIND_REQUEST, { inquiryId, bindRequest });
    },
    setHandoverNotes({ commit }, { handoverNotes }) {
      commit('setHandoverNotes', { handoverNotes });
    },
    async saveHandoverNotes({ commit }, { handoverNotes, inquiryId }) {

      const url = process.env.VUE_APP_API_ENDPOINT + "/progress/setHandoverNotes";

      try {
        let { data } = await axios.post(
            url,
            {
              inquiryId: inquiryId,
              handoverNotes: handoverNotes
            },
            {
              withCredentials: true
            }
        );
      } catch (err) {
        // Show error toast to user
        Vue.toasted.error(
            "An error occurred while saving Programme Inquiry, please try again."
        );
        console.error(err);
      }
    }
  },
  getters: {
    programme: state => state.programme,
    programmes: state => state.programmes,
    localPoliciesForProgramme: state => state.localPolicies,
    getAllInquiries: state => state.inquiries,
    getInquiryProgressDocumentTypes: state => state.inquiryProgressDocumentTypes,
    coverageDocuments: state => state.coverageDocuments,
    claims: state => state.claims,
    handoverNotes: state => state.handoverNotes
  }
};
