<template>
  <div class="modal-file-version-wrapper">
    <b-modal
      class="modal-file-version"
      :id="customModalId ?? 'modal-file-version-list'"
      size="lg"
    >
      <template v-slot:modal-header>
        <h2><i class="fa fa-book-open"></i>&nbsp;File Versions</h2>
      </template>
      <hr />

      <div class="p-3">
        <h3>Current Version</h3>
      </div>

      <table class="table" v-if="this.fileList[this.selectedFileVersionsIndex]">
        <thead>
          <tr>
            <th>Version</th>
            <th>File</th>
            <th>Date Added</th>
            <th>Download</th>
            <th>Delete</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="fileVersion in [
              this.fileList[this.selectedFileVersionsIndex]
                ?.slice()
                ?.reverse()[0]
            ]"
            :key="fileVersion?.inquiry_tool_progress_document_map_id"
          >
            <td>
              {{ fileVersion?.version }}
            </td>
            <td>
              {{ fileVersion?.document.filename }}
            </td>
            <td>
              {{ $options.filters.formatDateUK(fileVersion?.document?.date_created) }}
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                type="button"
                @click="download(fileVersion?.document_id)"
                :disabled="fileVersion?.loading"
                title="Download file"
              >
                <i class="fa fa-download"></i>
              </button>
            </td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="remove(fileVersion?.document_id)"
                :disabled="fileVersion?.loading"
                title="Remove file"
              >
                <i class="fa fa-window-close"></i>
              </button>
            </td>
            <td>
              <b-overlay :show="fileVersion?.loading"></b-overlay>
            </td>
          </tr>
        </tbody>
      </table>

			<span class="p-3" v-else>No files uploaded.</span>

      <div class="p-3">
        <h3>Previous Versions</h3>
      </div>
      <table
        class="table"
        v-if="
          this.fileList[this.selectedFileVersionsIndex]
            ?.slice()
            ?.reverse()
            ?.slice(1).length > 0
        "
      >
        <thead>
          <tr>
            <th>Version</th>
            <th>File</th>
            <th>Date Added</th>
            <th>Download</th>
            <th>Delete</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="fileVersion in this.fileList[this.selectedFileVersionsIndex]
              ?.slice()
              ?.reverse()
              ?.slice(1)"
            :key="fileVersion?.inquiry_tool_progress_document_map_id"
          >
            <td>
              {{ fileVersion?.version }}
            </td>
            <td>
              {{ fileVersion?.document.filename }}
            </td>
            <td>
              {{ $options.filters.formatDateUK(fileVersion?.document.date_created) }}
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                type="button"
                @click="download(fileVersion?.document_id)"
                :disabled="fileVersion?.loading"
                title="Download file"
              >
                <i class="fa fa-download"></i>
              </button>
            </td>
            <td>
              <button
                class="btn btn-danger btn-sm"
                type="button"
                @click="remove(fileVersion?.document_id)"
                :disabled="fileVersion?.loading"
                title="Remove file"
              >
                <i class="fa fa-window-close"></i>
              </button>
            </td>
            <td>
              <b-overlay :show="fileVersion?.loading"></b-overlay>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pl-3 pb-3 pt-0" v-else>
        There are no previous versions of this file.
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "UploaderFileVersionModal",
  props: {
    customModalId: {
      type: String
    },
    fileList: {
      type: Array,
      required: true
    },
    selectedFileVersionsIndex: {
      type: Number
    }
  },
  methods: {
    download(documentId) {
      this.$emit("download", documentId);
    },
    remove(documentId) {
      this.$emit("remove", documentId);
    }
  }
};
</script>
