<template>
  <div>
    <h4>Select Countries</h4>

    <b-badge v-for="country in selectedCountries" :key="country" class="mr-1">
      {{ $store.getters.getAllCountries.find((c) => c.country_id === country).country_name ?? '' }}
    </b-badge>

    <div class="row mb-2">
      <div class="col-md-6">
        <b-select v-model="selectedContinent" class="form-select">
          <option value="">Select Continent</option>
          <option v-for="continent in continents" :key="continent" :value="continent">{{ continent }}</option>
        </b-select>
      </div>

      <div class="col-md-6">
        <input type="text" v-model="countrySearchTerm" class="form-control" placeholder="Search by country name">
      </div>
    </div>

    <div class="row">
      <div class="col-md-4" v-for="countryOption in countryOptions" :key="countryOption.country_id">
        <b-form-checkbox v-model="selectedCountries" :value="countryOption.country_id">{{ countryOption.country_name }}</b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countryList: {
      type: Array
    }
  },
  data() {
    return {
      selectedContinent: '',
      selectedCountries: [],
      countrySearchTerm: '',
    };
  },
  computed: {
    continents() {
      return [...new Set(this.$store.getters.getAllCountries.map(item => item.continent))];
    },
    countries() {
      let countries = this.$store.getters.getAllCountries;

      if (this.countryList) {
        countries = countries.filter(
          country => this.countryList.includes(country.country_name)
        );
      }

      if (this.countrySearchTerm) {
        countries = countries.filter(
            country => country.country_name.toLowerCase().includes(this.countrySearchTerm.toLowerCase())
        );
      }

      return countries;
    },
    countryOptions() {
      return this.countries.filter(country => this.selectedContinent === '' || country.continent === this.selectedContinent);
    }
  },
  watch: {
    selectedCountries(newValue) {
      this.$emit("update:selectedCountries", newValue);
    }
  }
};
</script>