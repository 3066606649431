<template>
  <div class="home">
    <div class="row mt-2" id="root" v-if="selectedInquiry">
      <div class="col-6">
        <h5 class="mt-2">Attach Files to Inquiry</h5>
      </div>
      <div class="col-6">
        <div class="float-right">
          <button
            class="btn btn-primary"
            @click="bDocumentListView = !bDocumentListView"
          >
            <i class="fa fa-list" v-if="bDocumentListView" aria-hidden="true"></i>
            <i class="fa fa-th" v-else aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <div class="col-12" v-if="bDocumentListView">
       <inquiry-documents-table-view :documents="selectedInquiry.documents"></inquiry-documents-table-view>
      </div>
      <div class="col-12" v-else>
        <div class="row">

					<div class="col-sm-12">
						<div class="flex w-full h-screen items-center justify-center text-center p-2">
							<div class="p-12 border border-gray-300">
								<br>
								<h5>Handover Notes</h5>
								<div class="b-overlay-wrap position-relative">
									<vue-editor v-model="handoverNotes" :editorToolbar="customToolbar"></vue-editor>
								</div>
								<div class="p-2">
									<tooltip-button
										:text="'Save Handover Notes'"
										icon="save"
										btn-class="btn-outline-success"
										disabled-tooltip-level="info"
										disabled-tooltip-position="middle"
										@click="saveHandoverNotes()"
									></tooltip-button>
								</div>
							</div>
						</div>
					</div>

          <div
            v-for="inquiryProgressDocumentType in inquiryProgressDocumentTypes"
            v-bind:key="
              inquiryProgressDocumentType.inquiry_tool_progress_document_type_id
            "
            :class="{
              'col-md-4': !inquiryProgressDocumentType.flag_large_uploader,
              'col-lg-4': inquiryProgressDocumentType.flag_large_uploader,
              'col-sm-4': true
            }"
          >
            <uploader
              :uploaderId="
                inquiryProgressDocumentType.inquiry_tool_progress_document_type_id.toString()
              "
              :inquiry="selectedInquiry"
              :title="
                inquiryProgressDocumentType.inquiry_tool_progress_document_type_caption
              "
            ></uploader>
          </div>
        </div>
      </div>
    </div>
    <inquiry-actions :inquiry-id="this.inquiryId">
      <span class="float-right">
        <span v-if="$store.getters.submitting">
          <i class="fa fa-sync fa-spin"></i>&nbsp;Instructing Programme...
        </span>
        <tooltip-button
          v-else
          text="Instruct"
          icon="paper-plane"
          btn-class="btn-success"
          :disabled="!underwritingPortalMode() && $store.getters.submitted"
          disabled-tooltip-text="This programme has already been instructed"
          disabled-tooltip-level="success"
          disabled-tooltip-position="left"
          @click="submitProgramme"
        ></tooltip-button>
      </span>
    </inquiry-actions>
  </div>
</template>
<script>
import Uploader from "@/components/Uploader";
import InquiryActions from "@underwriters/src/components/InquiryActions";
import TooltipButton from "@underwriters/src/components/TooltipButton";
import FeaturesMixin from "@underwriters/src/mixins/features.mixin";
import InquiryDocumentsTableView from "@underwriters/src/components/InquiryDocumentsTableView";

import { VueEditor } from 'vue2-quill-editor'
import Vue from "vue";

export default {
  name: "AddFiles",
  components: {
    Uploader,
    InquiryActions,
    TooltipButton,
    InquiryDocumentsTableView,
		VueEditor
  },
  mixins: [FeaturesMixin],
  data: () => {
    return {
      bDocumentListView: false,
			handoverNotes: '',
			customToolbar: [
				['bold', 'italic', 'underline', 'strike', 'blockquote'],
				[{ 'header': 1 }, { 'header': 2 }],
				[{ 'script': 'sub'}, { 'script': 'super' }],
				[{ 'list': 'ordered'}, { 'list': 'bullet' }],
				[{ 'color': [] }, { 'background': [] }],
				['clean']
			]
    };
  },
	watch: {
		handoverNotes() {
			this.setHandoverNotes();
		}
	},
  computed: {
    selectedInquiry() {
      return (
        this.$store.getters.getAllInquiries.filter(
          inquiry => parseInt(inquiry.id) === parseInt(this.inquiryId)
        )[0] ?? null
      );
    },
    inquiryProgressDocumentTypes() {
      return this.$store.getters.getInquiryProgressDocumentTypes;
    }
  },
  props: {
    inquiryId: {
      type: [Number, String],
      default: null
    }
  },
  methods: {
    async submit() {
      this.$store.commit("setProgrammeSubmitted");
			await this.$store.dispatch("saveHandoverNotes", { handoverNotes: this.handoverNotes, inquiryId: this.inquiryId });
      await this.$store.dispatch("storeState");
      await this.$router.push({ name: "Instructed Accounts" });
    },
    async submitProgramme() {
      if (!this.$store.getters.isLoggedIn) {
        this.action = "Submit";
        this.$bvModal.show("modal-login");
      } else {
        await this.submit();
      }
    },
		async setHandoverNotes() {
			await this.$store.dispatch("setHandoverNotes", { handoverNotes: this.handoverNotes });
		},
		async saveHandoverNotes() {
			try {
				await this.$store.dispatch("saveHandoverNotes", {
					handoverNotes: this.handoverNotes,
					inquiryId: this.inquiryId,
				});

				Vue.toasted.success("Successfully saved the Handover Notes");
			} catch (error) {
				Vue.toasted.error("Failed to save Handover Notes. Please try again.");

				console.error(error);
			}
		}
  },
	mounted() {
		this.handoverNotes = this.$store.getters.handoverNotes;
	}
};
</script>

<style scoped>
.ql-snow.ql-toolbar {
	border-left: 0;
	border-right: 0;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.ql-snow.ql-container {
	border: 0;
}
</style>