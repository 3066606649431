export const FormatterMixin = {
  methods: {
    formatCountriesFromArray(aCountries) {
      if (!aCountries) {
        return [];
      }

      if (aCountries.length > 5) {
        aCountries = aCountries.slice(0, 5).concat(`... and ${aCountries.length - 5} more`);
      }
      return aCountries.map(country => country?.country?.country_name ?? country);
    },
    formatCountriesFromString(sCountries) {
      let aCountries = sCountries.split("<br />");
      aCountries = this.formatCountriesFromArray(aCountries);
      return aCountries.join("<br />");
    }
  }
};

export default FormatterMixin;
